// src/App.tsx
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import TableViewLayout from '../../../layouts/TableViewLayout';
import Breadcrumb from '../../../components/Breadcrumb';
import { fetchDashboardProductDatas, deleteAllEvents, deleteSingleEvent } from '../../../services/apiService';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation,faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import Nav from '../../../components/Nav';

interface DashboardProjectProps {
    setError: Dispatch<SetStateAction<string | null>>;
    setIsError: Dispatch<SetStateAction<boolean>>;
}

const Project: React.FC<DashboardProjectProps> = ({ setError, setIsError }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const project = searchParams.get('project');
    const page = searchParams.get('page');
    const currentDashboardPage = localStorage.getItem('currentDashboardPage')
    const navigate = useNavigate();
    const [deleteConformPopup,setDeleteConformPopup] = useState(false);
    const [currentDeleteEmail, setCurrentDeleteEmail] = useState('');
    const [platform,setPlatform] = useState('All')
    const [platformFromSlide,setPlatformFromSlide] = useState('')
    const [closeSlide,setCloseSlide] = useState('')
    const [filter,setFilter] = useState('')
    const [deleteType,setDeleteType] = useState('')
    const role = localStorage.getItem('role');

    const updatePageParam = (newPage: any) => {
        setLoading(true);
        searchParams.set('page', newPage.toString());
        setSearchParams(searchParams);
        setLoading(false);
    };

    const [data, setData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const handlePageChange = async (page: Number) => {
        setLoading(true);
        try{
            updatePageParam(page);
            const result = await fetchDashboardProductDatas(String(project), page , platform);
            setData(result.data);
        }
        catch{
            alert(`you are redirecting to page 1 because page ${page} is error`)
            updatePageParam(1);
            const result = await fetchDashboardProductDatas(String(project), 1 , platform);
            setData(result.data);
            setLoading(false);
        }
    };

    const getData = async (pageToLoad?:string) => {
        setLoading(true);
        try {
            const result = await fetchDashboardProductDatas(String(project), pageToLoad ? Number(pageToLoad) : Number(page) , platform);
            if(pageToLoad){
                updatePageParam(Number(pageToLoad));
            }
            setData(result.data);
        } catch (error: any) {
            // console.log(error)
            // setError(error.response.data.message);
            // setIsError(true);
            
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setLoading(true)
        getData();
    }, [project, page ]);

    useEffect(()=>{
        getData('1');
    },[platform])

    const breadcrumbs = [
        { label: 'Dashboard', href: '/dashboard?page=1' },
        { label: project?.replace(/[_-]/g, ' ').toUpperCase(), href: '' }
    ];

    if (loading && data == null) {
        return (
            <>
                <Breadcrumb breadcrumbs={breadcrumbs} />
                <div className='w-full h-[100vh] bg-white'>
                    <div role="status" className='bg-yellow-500 w-full h-full rounded-xl p-3 flex justify-center items-center'>
                        <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                        </svg>
                    </div>
                </div>
            </>
        );
    }
    const desiredValues = role === '1' ? data && data.dashboards !== undefined && data.dashboards.map((item: { id: any; account: { id: any; }; email: any; views: any; total_duration_seconds: number; last_seen: any; total_duration_s:any; shares:any; latestActivity:any;}) => ({
        email: item.email,
        views: item.views,
        duration: item.total_duration_seconds,
        shares: item.shares,
        latest_activity: item.latestActivity,
        last_seen: item.last_seen,
        deleteSpecificEvent: item.email
    }))
    :
    data && data.dashboards !== undefined && data.dashboards.map((item: { id: any; account: { id: any; }; email: any; views: any; total_duration_seconds: number; last_seen: any; total_duration_s:any; shares:any; latestActivity:any;}) => ({
        email: item.email,
        views: item.views,
        duration: item.total_duration_seconds,
        shares: item.shares,
        latest_activity: item.latestActivity,
        last_seen: item.last_seen,
    }))

    const top = (<div className="w-[70%] h-full flex">
        <div className="w-full bg-yellow-50/50 rounded-2xl ml-2 text-gray-700">
            <div className="text-xl font-bold flex justify-center items-end h-1/2">
                <select className='rounded bg-yellow-50/50 text-center border border-gray-300 text-sm' value={platform} onChange={e => setPlatform(e.target.value)}>
                    <option value='All'>All</option>
                    <option value='Android'>Android</option>
                    <option value='IOS'>IOS</option>
                    <option value='Webgl'>Webgl</option>
                    <option value='Spatial'>Spatial</option>
                </select>
            </div>
            <div className="text-sm font-bold flex justify-center h-1/2">
                Filter
            </div>
        </div>
        <div className="w-full bg-yellow-50/50 rounded-2xl ml-2 text-gray-700">
            <div className="text-xl font-bold flex justify-center items-end h-1/2">
                {data && data.dashboard_counts?.total_views}
            </div>
            <div className="text-sm font-bold flex justify-center h-1/2">
                Total Views
            </div>
        </div>
        <div className="w-full bg-yellow-50/50 rounded-2xl ml-2 p-1">
            <div className="text-xl font-bold flex justify-center items-end h-1/2 text-gray-700">
                {(Math.floor(data && data.dashboard_counts?.total_duration / 60) < 10 ? `0${Math.floor(data && data.dashboard_counts?.total_duration / 60)}` : (Math.floor(data && data.dashboard_counts?.total_duration / 60)))+':'+((data && data.dashboard_counts?.total_duration % 60) < 10 ? `0${data && data.dashboard_counts?.total_duration % 60}`: data && data.dashboard_counts?.total_duration % 60)} <span className="ml-1.5 text-sm font-normal text-gray-700">min:sec</span>
            </div>
            <div className="text-sm font-bold flex justify-center h-1/2 text-gray-700">
                Total Duration
            </div>
        </div>
        <div className="w-full bg-yellow-50/50 rounded-2xl ml-2 text-gray-700">
            <div className="text-xl font-bold flex justify-center items-end h-1/2">
                {Math.round(data && (data.dashboard_counts?.total_duration / data.dashboard_counts?.total_views))} <span className="ml-1.5 text-sm font-normal ">sec</span>
            </div>
            <div className="text-sm font-bold flex justify-center h-1/2">
                Average Time
            </div>
        </div>
        <div className="w-full bg-yellow-50/50 rounded-2xl ml-2 text-gray-700">
            <div className="text-xl font-bold flex justify-center items-end h-1/2">
                {data && data.dashboard_counts?.share_count} <span className="ml-1.5 text-sm font-normal "></span>
            </div>
            <div className="text-sm font-bold flex justify-center h-1/2">
                Shares
            </div>
        </div>
        {role === '1' &&
            <div className="w-full bg-yellow-50/50 rounded-2xl ml-2 text-gray-700 cursor-pointer" onClick={()=>deletePopup('all')}>
                <div className="text-xl font-bold flex justify-center items-end h-1/2">
                    <FontAwesomeIcon icon={faTrash} className='ml-1.5 mb-1 text-red-600 rounded' />
                </div>
                <div className="text-sm font-bold flex justify-center h-1/2">
                    Delete All Data
                </div>
            </div>
        }
    </div>);

    async function deleteAllEvent(){
        setLoading(true);
        try{
            const result = await deleteAllEvents(project,platform)
            if(result.data.message == 'All events deleted successfully.'){
                getData();
            }
            else{
                setDeleteConformPopup(false)
                alert(result.data.message)
            }
            setCloseSlide('closeSilde')
        }
        catch(error: any) {
            setDeleteConformPopup(false)
            setError(error.response.data.message);
            setIsError(true);
            
        }
        finally{
            setLoading(false);
        }
    }

    async function deleteSpecificEvent(){
        setLoading(true);
        try{
            setDeleteConformPopup(false)
            const result = await deleteSingleEvent(project,currentDeleteEmail,platform)
            if(result.data.message == 'Events deleted successfully.'){
                getData();
            }
            else{
                alert(result.data.message)
            }
            setCloseSlide('closeSilde')
        }
        catch(error: any) {
            setDeleteConformPopup(false)
            setError(error.response.data.message);
            setIsError(true);
            setLoading(true);
        }
        finally{
            setLoading(false);
        }
    }

    async function deleteSpecificEventFromSlide(){
        setLoading(true);
        if(platformFromSlide == ''){
            alert('Platform is empty')
        }
        else{
            try{
                setDeleteConformPopup(false)
                const result = await deleteSingleEvent(project,currentDeleteEmail,platformFromSlide)
                if(result.data.message == 'Events deleted successfully.'){
                    getData();
                }
                else{
                    alert(result.data.message)
                }
                setCloseSlide('closeSilde')
            }
            catch(error: any) {
                setDeleteConformPopup(false)
                setError(error.response.data.message);
                setIsError(true);
                setLoading(true);
            }
            finally{
                setLoading(false);
            }
        }
    }

    function deletePopup(type:string){
        setDeleteType(type)
        setDeleteConformPopup(true)
    }

    return (
        <div className='w-full h-full mt-1'>
            <Nav />
            <Breadcrumb breadcrumbs={breadcrumbs} />
            <div className='bg-yellow-500 w-full h-auto rounded-t-xl p-3'>
                <TableViewLayout
                    data = {{
                        clickable_value: 'All Event Details',
                        clickable_link_type: null,
                        top: top,
                        headers: role === '1' ? ["email", "views", "duration(sec)","shares","latest activity", "last_seen","delete"] : ["email", "views", "duration(sec)","shares","latest activity", "last_seen"],
                        values: desiredValues,
                        total_pages: data && data.total_pages,
                        current_page: data && data.current_page,
                        title: project || "",
                        sub_title: "Story engagement Data",
                        currentProject: project,
                        filter: platform,
                        page: searchParams.get('page'),
                        closeSlide: closeSlide
                    }}
                    handlePageChange = {handlePageChange}
                    deleteSpecificEvent = {(email: string) => {
                        deletePopup('specific')
                        setCurrentDeleteEmail(email)
                    }}
                    deleteSpecificEventFromSlide = {(email: string,platFromSlide: string) => {
                        deletePopup('specificFromSlide')
                        setCurrentDeleteEmail(email)
                        setPlatformFromSlide(platFromSlide)
                    }}
                    loadingToggle = {(load: any) => setLoading(load)}
                />
            </div>
            {deleteConformPopup &&
                <div className='fixed z-50 top-0 bottom-0 left-0 right-0 flex justify-center items-center'>
                    <div className='w-1/3 bg-white border-2 p-10 bg-white' style={{borderRadius:'25px'}}>
                        <div className='flex items-center justify-center mb-5'>
                            <FontAwesomeIcon icon={faCircleExclamation} className='bg-red-100 border border-red-500 p-2 text-red-500 text-2xl rounded-full'/>
                        </div>
                        <h3 className='text-center mb-2 font-bold'>Are you Sure?</h3>
                        {
                            deleteType == 'all' ? 
                                <p className='text-center text-gray-500'>This will delete the all {platform == 'All' ? '' : `"${platform}"`} events of this project permanently. You cannot undo this action.</p>
                            : deleteType == 'specific' ?
                                <p className='text-center text-gray-500'>This will delete the {platform == 'All' ? '' : `"${platform}"`} events of this "{currentDeleteEmail}" user permanently. You cannot undo this action.</p>
                            :
                                <p className='text-center text-gray-500'>This will delete the "{platformFromSlide}" events of this "{currentDeleteEmail}" user permanently. You cannot undo this action.</p>
                        }
                        <div className='flex justify-center items-center mt-2'>
                            <button className='me-3 bg-red-400 text-white px-5 hover:bg-red-600 rounded' onClick={e=>{
                                e.preventDefault()
                                deleteType == 'all' ? deleteAllEvent() : deleteType == 'specific' ? deleteSpecificEvent() : deleteSpecificEventFromSlide()
                            }}>Delete</button>
                            <button className='bg-gray-500 text-white px-6 hover:bg-gray-600 rounded' onClick={e=>{
                                e.preventDefault()
                                setDeleteConformPopup(false)
                            }}>Cancel</button>
                        </div>
                    </div>
                </div>
            }
            {loading &&
                <div style={{position:'fixed',top:'0',bottom:'0',width:'100%',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
                    <div style={{background:'white',border:' 2px solid #0050C8'}} className="flex items-center rounded px-7 py-3">
                        <div className="spinner me-2"></div>
                        {
                            <p className="text-blue-700">Loading...</p>
                        }
                    </div>
                </div>
            }
        </div>
    );
};

export default Project;