import { useEffect, useState } from "react";
import CustomFieldCreateBtn from "../CustomFieldCreateBtn";
import Form from "./Form";
import { createProject, deleteConfiguratorProjectCustomField, fetchConfiguratorProjectData, fetchOptionsAccount, audioConvert, audioUpload, fileUpload , projectTableColumnFetch , audioEnhancer, assestUpload, get3dRepo , lastIDOfProject} from "../../services/apiService";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios'
import { AxiosError } from 'axios';
import '../../custom.css'
  

interface ProjectFormProps {
    setError: any;
    setIsError: any;
}

const ProjectForm: React.FC<ProjectFormProps> = ({ setError, setIsError }) => {
    const [projectCount, setProjectCount] = useState(0);
    const [projectData, setProjectData] = useState<any[]>([{onboarding_audio_url:'',uploaded_model:''}]);
    const [accountOption, setAccountOption] = useState([]);
    const [audioUrl, setAudioUrl] = useState(''); 
    const [audioUploaded,setAudioUploaded] = useState(false);
    const [imageUploaded,setImageUploaded] = useState(false);
    const [modelUploaded,setModelUploaded] = useState(false);
    const [androidAssestUploaded,setAndroidAssestUploaded] = useState(false);
    const [iosAssestUploaded,setIosAssestUploaded] = useState(false);
    const [webglAssestUploaded,setWebglAssestUploaded] = useState(false);
    const [spatialAssestUploaded,setSpatialAssestUploaded] = useState(false);
    const [alreadyAudioUploaded,setAlreadyAudioUploaded] = useState(false);
    const [alreadyImageUploaded,setAlreadyImageUploaded] = useState(false);
    const [alreadyModelUploaded,setAlreadyModelUploaded] = useState(false);
    const [alreadyAndroidAssestUploaded,setAlreadyAndroidAssestUploaded] = useState(false);
    const [alreadyIosAssestUploaded,setAlreadyIosAssestUploaded] = useState(false);
    const [alreadyWebglAssestUploaded,setAlreadyWebglAssestUploaded] = useState(false);
    const [alreadySpatialAssestUploaded,setAlreadySpatialAssestUploaded] = useState(false);
    const [audioUploadFailed,setAudioUploadFailed] = useState(false)
    const [customModel,setCustomModel] = useState(false)
    const [lastPro,setLastPro] = useState(0)

    const navigate = useNavigate();
    const account_id = localStorage.getItem('account_id')
    const role = localStorage.getItem('role');

    const defaultFields = (account_id === '1' && role === '7') ?
        [
            { name: 'project_name', label: 'Story Name', placeholder: 'Story name', type: "text" , limit:'20'},
            { name: 'code', label: 'Code', placeholder: 'Story code' , limit:'6'},
            { name: 'division', label: 'Category', placeholder: 'Story division' , limit:'20' },
            { name: 'product_model', label: '3D Model Title', placeholder: 'Product model' , limit:'20' },

            { name: 'line_break', label: 'line break', placeholder: 'line break',type:'lineBreak'},
            // { name: 'already_image_upload_status', label: 'Branding Image', placeholder: 'Already image upload status',type:'status'},
            { name: 'select_image', label: 'Upload Branding Image(500 x 120)', placeholder: 'select image' , type:'file'},
            { name: 'img_button', label: 'Upload Image', placeholder: 'Upload Image',type:'button'},
            // { name: 'branding_bg_url', label: 'Branding background url', placeholder: 'Branding background URL',disabled:true },
            // { name: 'image_upload_status', label: 'Image upload status', placeholder: 'Image upload status',type:'status'},

            { name: 'line_break', label: 'line break', placeholder: 'line break',type:'lineBreak'},
            // { name: 'already_model_upload_status', label: '3D Model', placeholder: 'Already model upload status',type:'status'},
            { name: 'select_model', label: 'Upload 3D Model (.fbx within 50 mb)', placeholder: 'select model' , type:'file'},
            { name: 'model_button', label: 'Upload Model', placeholder: 'Upload Model',type:'button'},
            // { name: 'uploaded_model', label: 'Uploaded Model URL', placeholder: 'Uploaded Model URL',disabled:true },
            // { name: 'model_upload_status', label: 'Model upload status', placeholder: 'Model upload status',type:'status'},
            { name: 'line_break', label: 'line break', placeholder: 'line break',type:'lineBreak'},
            { name: 'custom_model', label: 'Custom Model', placeholder: 'Custom Model',type:'checkbox'},
            { name: 'line_break', label: 'line break', placeholder: 'line break',type:'lineBreak'},

            // { name: 'android_app_url', label: 'Android app url', placeholder: 'Android app URL' },
            // { name: 'ios_app_url', label: 'IOS app url', placeholder: 'iOS app URL' },
            // { name: 'webgl_app_url', label: 'WebGL app url', placeholder: 'WebGL app URL' },
            // { name: 'spatial_app_url', label: 'Spatial app url', placeholder: 'Spatial app URL' },
            { name: 'android_app_assest', label: 'Android Assests', placeholder: 'Android App Assests' , type:'file'},
            { name: 'android_button', label: 'Upload Android Assest', placeholder: 'Upload Android',type:'button'},
            { name: 'line_break', label: 'line break', placeholder: 'line break',type:'lineBreak'},

            { name: 'ios_app_assest', label: 'IOS Assest', placeholder: 'IOS App Assest' , type:'file'},
            { name: 'ios_button', label: 'Upload IOS Assest', placeholder: 'Upload IOS',type:'button'},
            { name: 'line_break', label: 'line break', placeholder: 'line break',type:'lineBreak'},

            { name: 'webgl_app_assest', label: 'Webgl Assest', placeholder: 'Webgl App Assest' , type:'file'},
            { name: 'webgl_button', label: 'Upload Webgl Assest', placeholder: 'Upload Webgl',type:'button'},
            { name: 'line_break', label: 'line break', placeholder: 'line break',type:'lineBreak'},

            { name: 'spatial_app_assest', label: 'Spatial Assest', placeholder: 'Spatial App Assest' , type:'file'},
            { name: 'spatial_button', label: 'Upload Spatial Assest', placeholder: 'Upload Spatial',type:'button'},

            { name: 'line_break', label: 'line break', placeholder: 'line break',type:'lineBreak'},
            { name: 'default_zoom', label: '3D Model Zoom(Default: 50%-200%)', placeholder: 'Customize Zoom Range :',type:'checkbox'},
            { name: 'minimum_zoom', label: 'Minimum Zoom', placeholder: 'Minimum zoom',type:'select' },
            { name: 'maximum_zoom', label: 'Maximum Zoom', placeholder: 'Maximum zoom',type:'select' },

            { name: 'line_break', label: 'line break', placeholder: 'line break',type:'lineBreak'}, 
            // { name: 'already_audio_upload_status', label: 'Audio', placeholder: 'Already audio upload status',type:'status'},
            { name: 'Text_for_audio', label: 'Input Text For Voiceover', placeholder: 'Text for audio' , limit:'400' },
            { name: 'audio_button', label: 'Convert to audio', placeholder: 'convert to audio',type:'button'},
            // { name: 'onboarding_audio_url', label: 'Onboarding audio url', placeholder: 'Onboarding audio URL',disabled:true },
            // { name: 'audio_upload_status', label: 'Audio upload status', placeholder: 'Audio upload status',type:'status'},

            { name: 'line_break', label: 'line break', placeholder: 'line break',type:'lineBreak'}, 
            { name: 'collect_mail', label: 'Collect Customer Email Addresses', placeholder: 'Yes, I would like to collect email addresses :',type:'checkbox'},
            { name: 'default_mail', label: 'Default mail', placeholder: 'Default mail',type:'email'},

            { name: 'line_break', label: 'line break', placeholder: 'line break',type:'lineBreak'}, 
            { name: 'share', label: 'Share', placeholder: 'share',type:'checkbox'},
            { name: 'line_break', label: 'line break', placeholder: 'line break',type:'lineBreak'},
            { name: 'tracking_mode', label: 'Tracking mode', placeholder: 'Tracking mode',type:'select' },
            { name: 'ui_placement', label: 'UI Placement Type', placeholder: 'UI Placement Type',type:'select' },
            { name: 'collect_experience_data', label: 'Collect Experience Data', placeholder: 'Collect Experience Data',type:'checkbox'},
            // { name: 'background_audio_url', label: 'Background Audio URL', placeholder: 'Background Audio URL'},
            
            // { name: 'model_upload', label: 'Tracking mode', placeholder: 'Tracking mode' },
            // { name: 'template_type', label: 'Template type', placeholder: 'Template type', type:'select', customField: false }
        ]
    :
        [
            { name: 'project_name', label: 'Story Name', placeholder: 'Story name', type: "text" , limit:'20'},
            { name: 'code', label: 'Code', placeholder: 'Story code' , limit:'6'},
            { name: 'division', label: 'Category', placeholder: 'Story division' , limit:'20' },
            { name: 'product_model', label: '3D Model Title', placeholder: 'Product model' , limit:'20' },

            { name: 'line_break', label: 'line break', placeholder: 'line break',type:'lineBreak'},
            // { name: 'already_image_upload_status', label: 'Branding Image', placeholder: 'Already image upload status',type:'status'},
            { name: 'select_image', label: 'Upload Branding Image(500 x 120)', placeholder: 'select image' , type:'file'},
            { name: 'img_button', label: 'Upload Image', placeholder: 'Upload Image',type:'button'},
            // { name: 'branding_bg_url', label: 'Branding background url', placeholder: 'Branding background URL',disabled:true },
            // { name: 'image_upload_status', label: 'Image upload status', placeholder: 'Image upload status',type:'status'},

            { name: 'line_break', label: 'line break', placeholder: 'line break',type:'lineBreak'},
            // { name: 'already_model_upload_status', label: '3D Model', placeholder: 'Already model upload status',type:'status'},
            { name: 'select_model', label: 'Upload 3D Model (.fbx within 50 mb)', placeholder: 'select model' , type:'file'},
            { name: 'model_button', label: 'Upload Model', placeholder: 'Upload Model',type:'button'},
            // { name: 'uploaded_model', label: 'Uploaded Model URL', placeholder: 'Uploaded Model URL',disabled:true },
            // { name: 'model_upload_status', label: 'Model upload status', placeholder: 'Model upload status',type:'status'},

            // { name: 'line_break', label: 'line break', placeholder: 'line break',type:'lineBreak'},

            // { name: 'android_app_url', label: 'Android app url', placeholder: 'Android app URL' },
            // { name: 'ios_app_url', label: 'IOS app url', placeholder: 'iOS app URL' },
            // { name: 'webgl_app_url', label: 'WebGL app url', placeholder: 'WebGL app URL' },
            // { name: 'spatial_app_url', label: 'Spatial app url', placeholder: 'Spatial app URL' },

            { name: 'from_repository', label: 'Choose from our repository', placeholder: 'Choose from our repository',type:'checkbox'},
            { name: 'repository_model', label: 'Select a model', placeholder: 'Select a model',type:'select' },

            { name: 'line_break', label: 'line break', placeholder: 'line break',type:'lineBreak'},
            // { name: 'already_audio_upload_status', label: 'Audio', placeholder: 'Already audio upload status',type:'status'},
            { name: 'Text_for_audio', label: 'Input Text For Voiceover', placeholder: 'Text for audio' , limit:'400' },
            { name: 'audio_button', label: 'Convert to audio', placeholder: 'convert to audio',type:'button'},
            // { name: 'onboarding_audio_url', label: 'Onboarding audio url', placeholder: 'Onboarding audio URL',disabled:true },
            // { name: 'audio_upload_status', label: 'Audio upload status', placeholder: 'Audio upload status',type:'status'},

            { name: 'line_break', label: 'line break', placeholder: 'line break',type:'lineBreak'}, 
            { name: 'default_zoom', label: '3D Model Zoom(Default: 50%-200%)', placeholder: 'Customize Zoom Range :',type:'checkbox'},
            { name: 'minimum_zoom', label: 'Minimum Zoom', placeholder: 'Minimum zoom',type:'select' },
            { name: 'maximum_zoom', label: 'Maximum Zoom', placeholder: 'Maximum zoom',type:'select' },

            { name: 'line_break', label: 'line break', placeholder: 'line break',type:'lineBreak'}, 
            { name: 'collect_mail', label: 'Collect Customer Email Addresses', placeholder: 'Yes, I would like to collect email addresses :',type:'checkbox'},
            { name: 'default_mail', label: 'Default mail', placeholder: 'Default mail',type:'email'},

            { name: 'line_break', label: 'line break', placeholder: 'line break',type:'lineBreak'}, 
            { name: 'share', label: 'Share', placeholder: 'share',type:'checkbox'},
            { name: 'line_break', label: 'line break', placeholder: 'line break',type:'lineBreak'},
            { name: 'tracking_mode', label: 'Tracking mode', placeholder: 'Tracking mode',type:'select' },
            { name: 'ui_placement', label: 'UI Placement Type', placeholder: 'UI Placement Type',type:'select' },
            { name: 'collect_experience_data', label: 'Collect Experience Data', placeholder: 'Collect Experience Data',type:'checkbox'},
            // { name: 'background_audio_url', label: 'Background Audio URL', placeholder: 'Background Audio URL'},
            
            // { name: 'model_upload', label: 'Tracking mode', placeholder: 'Tracking mode' },
            // { name: 'template_type', label: 'Template type', placeholder: 'Template type', type:'select', customField: false }
        ]

    const [projectFields, setProjectFields] = useState<{ [key: string]: typeof defaultFields }[]>([]);
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingMes,setLoadingMes]= useState('')
    const [popupForAudioComparision,setPopupForAudioComparision] = useState(false)
    const [enhancedTextVersion,setEnhancedTextVersion] = useState('')
    const [tracking_mode_list,setTracking_mode_list] = useState([])
    const [template_type_list,setTemplate_type_list] = useState([])
    const [uiPlacement_type_list,setUiPlacement_type_list] = useState([])
    const [threeDRepoOptions,setThreeDRepoOptions] = useState([])
    const [sms,setSms] = useState(false)

    const transformProjectData = (data: any) => {
        return {
            account_name: data.account_name,
            project_name: data.project_name,
            code: data.code,
            division: data.division,
            product_model: data.product_model,
            bg_image_url: data.bg_image_url,
            Text_for_audio: data.Text_for_audio,
            onboarding_audio_url: data.onboarding_audio_url,
            uploaded_model: data.uploaded_model,
            from_repository: data.from_repository || false,
            repository_model: data.repository_model,
            custom_model: data.custom_model || false,
            default_zoom: data.default_zoom || 'true',
            minimum_zoom: data.minimum_zoom,
            maximum_zoom: data.maximum_zoom,
            collect_mail: data.collect_mail || 'true',
            default_mail: data.default_mail || false,
            share: data.share || false,
            android_app_url: data.android_app_url,
            ios_app_url: data.ios_app_url,
            webgl_app_url: data.webgl_app_url,
            spatial_app_url: data.android_app_url,
            branding_bg_url: data.branding_bg_url,
            tracking_mode: data.tracking_mode,
            template_type: data.template_type,
            ui_placement: data.ui_placement,
            collect_experience_data: data.collect_experience_data || false,
            background_audio_url: data.background_audio_url,
            project_custom_fields: data.project_custom_fields,
        };
    };
    
    async function getLastIDOfProject(){
         const res = await lastIDOfProject()
         return res.id
    }
 
    useEffect(()=>{
        async function ff(){
            if(projectData.length === 0){
                const lastId = await getLastIDOfProject();
                if(role === '1'){
                    setProjectData([
                        {
                            default_zoom: 'true',
                            collect_mail: 'true',
                            share: false,
                            code: "PRO"+lastId,
                            project_name: "PRO"+lastId,
                            account_name: 'XORO Story',
                            collect_experience_data: false
                        },
                    ]);
                }
                else{
                    setProjectData([
                        {
                            default_zoom: 'true',
                            collect_mail: 'true',
                            share: false,
                            code: "PRO"+lastId,
                            project_name: "PRO"+lastId,
                            collect_experience_data: false
                        },
                    ]);
                }
            }
            if(projectData.length !== 0 && 'custom_model' in projectData[0]){
                setCustomModel(projectData[0]['custom_model'])
            }
        }
        ff()
    },[projectData])

    useEffect(()=>{
        if(projectData[0]){
            if(projectData[0]['branding_bg_url'] && projectData[0]['branding_bg_url'] != ''){
                setAlreadyImageUploaded(true)
            }
            if(projectData[0]['onboarding_audio_url'] && projectData[0]['onboarding_audio_url'] != ''){
                setAlreadyAudioUploaded(true)
            }
            if(projectData[0]['uploaded_model'] && projectData[0]['uploaded_model'] != ''){
                setAlreadyModelUploaded(true)
            }
            if(projectData[0]['android_app_url'] && projectData[0]['android_app_url'] != ''){
                setAlreadyAndroidAssestUploaded(true)
            }
            if(projectData[0]['ios_app_url'] && projectData[0]['ios_app_url'] != ''){
                setAlreadyIosAssestUploaded(true)
            }
            if(projectData[0]['webgl_app_url'] && projectData[0]['webgl_app_url'] != ''){
                setAlreadyWebglAssestUploaded(true)
            }
            if(projectData[0]['spatial_app_url'] && projectData[0]['spatial_app_url'] != ''){
                setAlreadySpatialAssestUploaded(true)
            }
        }
    },[projectData])

    useEffect(() =>{
        setLoading(true)
        async function colFetchFun1(){
            const res = await projectTableColumnFetch('tracking_mode_list')
            const values = res.data.column_data.filter((val:string)=> (val !== null && val !== ''))
            setTracking_mode_list(values)

            const res2 = await projectTableColumnFetch('template_type_list')
            const values2 = res2.data.column_data.filter((val:string)=> (val !== null && val !== ''))
            setTemplate_type_list(values2)

            const res3 = await projectTableColumnFetch('ui_placement_type_list')
            const values3 = res3.data.column_data.filter((val:string)=> (val !== null && val !== ''))
            setUiPlacement_type_list(values3)

            const res4 = await get3dRepo()
            setThreeDRepoOptions(res4)

            setLoading(false)
        }
        colFetchFun1()
    },[])

    useEffect(() => {
        setProjectCount(projectData.length);
    }, [projectData]);

    useEffect(() => {
        setLoading(true)
        const getData = async () => {
            try {
                const result = await fetchConfiguratorProjectData(String(code));
                if (result.status === 200) {
                    setProjectData([transformProjectData(result.data.project)]);
                    setLoading(false);
                }
            } catch (error) {
                setIsError(true);
                if (error instanceof Error) {
                    setError(error.message);
                } else {
                    setError("An unexpected error occurred");
                }
            } finally {
                
            }
        };


        const getAccountsOpptions = async () => {
            try {
                const result = await fetchOptionsAccount();
                if (result.status === 200) {
                    setAccountOption(result.data.accounts);
                    setLoading(false);
                }
            } catch (error) {
                setIsError(true);
                if (error instanceof Error) {
                    setError(error.message);
                } else {
                    setError("An unexpected error occurred");
                }
            } finally {
                
            }
        };

        if (code != null) {
            getData();
        } else {
            setProjectData([]);
            setProjectCount(0);
        }
        getAccountsOpptions();
    }, [code, setError, setIsError]);

    useEffect(() => {
        if (projectCount === 0 && projectData.length === 0) {
            if (accountOption.length !== 0) {
                const accountField = [{ name: 'account_name', label: 'Select account', type: 'select', placeholder: 'Select account', value: accountOption }];
                defaultFields.unshift(...accountField);
            }
            setProjectFields([{ project: [...defaultFields] }]);
        } else if (projectData.length > 0) {
            if (accountOption.length !== 0) {
                const accountField = [{ name: 'account_name', label: 'Select account', type: 'select', placeholder: 'Select account', value: accountOption }];
                defaultFields.unshift(...accountField);
            }
            const initialFields = Array.from({ length: projectCount }, () => ({
                project: [...defaultFields],
            }));
            initialFields[0] && projectData.forEach((data, index) => {
                const projectFields = initialFields[index]["project"];
                const projectCustomFields = data.project_custom_fields || {};
                const initialCustomFields = Object.values(projectCustomFields).map((customField: any) => ({
                    name: customField.attribute_value,
                    label: customField.attribute_value,
                    placeholder: customField.attribute_value,
                    value: customField.value,
                    customField: true,
                    type: customField.field_type,
                    id: customField.id
                }));

                if (projectFields) {
                    projectFields.push(...initialCustomFields);
                }
            });
            setProjectFields(initialFields);
        } else if (projectFields.length < projectCount) {
            setProjectFields(prev => {
                const newFields = Array.from({ length: projectCount - prev.length }, (_, index) => ({
                    [`project`]: [...defaultFields]
                }));
                return [...prev, ...newFields];
            });
        }
    }, [projectCount, projectData, accountOption]);

    useEffect(()=>{
        if(projectData[0] && projectData[0]['project_name']){
            localStorage.setItem('project_name', projectData[0]['project_name']);
        }
    },[projectData])

    const handleProjectChange = (
        e: (React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>),
            index: number,
            field: boolean
        ) => {
            const name = e.target.name
            const value = (e.target.name == 'share' || e.target.name == 'custom_model' || e.target.name == 'collect_experience_data' || e.target.name == 'from_repository') ? (e.target.value == 'true' ? true : false) : e.target.value
            const type = e.target.type

            setProjectData((prev: any[]) => {
                const updatedProject = [...prev];
                const project = updatedProject[index] || {};

                project[name] = value;
                if(name == 'android_app_url'){
                    project['spatial_app_url'] = value;
                }
                if(name == 'default_zoom' && value == 'false'){
                    project['minimum_zoom'] = null
                    project['maximum_zoom'] = null
                }
                if(name == 'collect_mail' && value == 'false'){
                    project['default_mail'] = ''
                }

                if(name === 'from_repository'){
                    threeDRepoOptions.forEach((list:any) => {
                        if(list.id.toString() == value.toString()){
                            setProjectData((prevData) => {
                                const updatedProjectData = [...prevData];
                                if (updatedProjectData.length > 0) {
                                    updatedProjectData[0].uploaded_model = '';
                                } else {
                                    updatedProjectData.push({ uploaded_model: '' });
                                }
                                return updatedProjectData;
                            });
                        }
                    })
                    setSms(false)
                }

                if(name == 'repository_model'){
                    threeDRepoOptions.forEach((list:any) => {
                        if(list.name == value){
                            setProjectData((prevData) => {
                                const updatedProjectData = [...prevData];
                                if (updatedProjectData.length > 0) {
                                    updatedProjectData[0].uploaded_model = list.model_url;
                                } else {
                                    updatedProjectData.push({ uploaded_model: list.model_url });
                                }
                                return updatedProjectData;
                            });
                        }
                    })
                    setSms(true)
                }

                if (field) {
                    const projectCustomFields = project.project_custom_fields || {};
                    const fieldIndex = Object.keys(projectCustomFields).findIndex(key => projectCustomFields[key].attribute_value === name);
                    if (fieldIndex !== -1) {
                        projectCustomFields[fieldIndex] = {
                            ...projectCustomFields[fieldIndex],
                            value: value,
                        };
                    } else {
                        projectCustomFields[Object.keys(projectCustomFields).length] = {
                            id: projectCustomFields[Object.keys(projectCustomFields).length]?.id,
                            attribute_value: name,
                            field_type: type,
                            value: value,
                        };
                    }
                    project.project_custom_fields = projectCustomFields;
                }
                updatedProject[index] = project;
                return updatedProject;
            });
            
    };

    const audioConvert1 = async (e:any) => {
        e.preventDefault();
        setLoading(true)
        setLoadingMes('converting')
        try {
       
            if(!projectData[0]){
                alert('Enter project name')
            }
            else if(!projectData[0]['project_name']){
                alert('Enter project name')
            }
            else if(projectData[0]['project_name'] === '' || projectData[0]['project_name'] === null){
                alert('Enter project name')
            }
            else if(!projectData[0]['Text_for_audio']){
                alert('Enter text for audio convertion')
            }
            else if(projectData[0]['Text_for_audio'] == '' || projectData[0]['Text_for_audio'] == null){
                alert('Enter text for audio convertion')
            }
            else if(projectData[0] && projectData[0]['Text_for_audio']){
                const result = await audioEnhancer(projectData[0]['Text_for_audio'])
                if(result.data.status && result.data.status == 'success'){
                    setEnhancedTextVersion(result.data.response.choices[0].message.content)
                    setPopupForAudioComparision(true)
                }
            }
            else{
                alert('something went worng in audio convertion')
            }
        } catch (error) {
            setIsError(true);
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError("An unexpected error occurred");
            }
        } finally {
            setLoading(false);
        }
    }

    const audioConvertionAfterComparision = async (e:any,selected:any) => {
        e.preventDefault();
        setPopupForAudioComparision(false)
        setLoading(true)
        setLoadingMes('converting')
        setAudioUrl('')
        try {
            let text;
            if(selected === 'enhancedOne'){
                setProjectData(prevData => { 
                    const newData = [...prevData]; 
                    newData[0]['Text_for_audio'] = enhancedTextVersion; 
                    return newData; 
                })
                text = enhancedTextVersion
            }
            else{
                text = projectData[0]['Text_for_audio']
            }
            const result = await audioConvert(text);
            const url = URL.createObjectURL(result.data);
            setAudioUrl(url)
        } catch (error) {
            setIsError(true);
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError("An unexpected error occurred");
            }
        } finally {
            setLoading(false);
        }
    }

    const audioUpload1 = async (e:any) => {
        e.preventDefault();
        setLoading(true)
        setLoadingMes('uploading')
        try {
            const result = await audioUpload(projectData[0]['Text_for_audio'],projectData[0]['project_name'],'project',localStorage.getItem('project_name'));
            if(result.status == 200){
                setAudioUploaded(true)
                setProjectData((prevData) => {
                    const updatedProjectData = [...prevData];
                    if (updatedProjectData.length > 0) {
                        updatedProjectData[0].onboarding_audio_url = result.data.s3_key;
                      } else {
                        updatedProjectData.push({ onboarding_audio_url: result.data.s3_key });
                      }
                    return updatedProjectData;
                });
                setAudioUrl('')
                setAudioUploadFailed(false)
            }
        } catch (error) {
            setAudioUploadFailed(true)
            setIsError(true);
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError("An unexpected error occurred");
            }
        } finally {
            setLoading(false);
        }
    }

    const model_upload = async (formData:any) => {
        if(projectData[0] && projectData[0]['project_name'] && projectData[0]['project_name'] !== ''){
            setLoading(true)
            setLoadingMes('uploading')
            const file = formData.get('file');  
            const fileName = file.name; 
            const fileExtension = fileName.split('.').pop(); 
            if(fileExtension === 'fbx'){
                const result = await fileUpload(formData,projectData[0].project_name,localStorage.getItem('project_name'))
                setProjectData((prevData) => {
                    const updatedProjectData = [...prevData];
                    if (updatedProjectData.length > 0) {
                        updatedProjectData[0].uploaded_model = result.data.s3_key;
                    } else {
                        updatedProjectData.push({ uploaded_model: result.data.s3_key });
                    }
                    return updatedProjectData;
                });
                setModelUploaded(true)
                setLoading(false);
                setSms(true)
                return result
            }
            else{
                alert('only ".fbx" files accepted')
                const result = {status : 4000}
                setLoading(false);
                return result
            }
        }
        else{
            alert('Enter project name and code')
            const result = {status : 4000}
            setLoading(false);
            return result
        }
    }

    const image_upload = (formData:any) => {
        setLoading(true);
        setLoadingMes('uploading');
        return new Promise((resolve, reject) => {
            const file = formData.get('file');
            const fileName = file.name;
            const fileExtension = fileName.split('.').pop();
            if (fileExtension === 'png' || fileExtension === 'jpeg') {
                const img = new Image();
                img.onload = async () => {
                    if (img.width === 500 && img.height === 120) {
                        const result = await fileUpload(formData, 'project', localStorage.getItem('project_name'));
                        setImageUploaded(true);
                        setProjectData((prevData) => {
                            const updatedProjectData = [...prevData];
                            if (updatedProjectData.length > 0) {
                                updatedProjectData[0].branding_bg_url = result.data.s3_key;
                            } else {
                                updatedProjectData.push({ branding_bg_url: result.data.s3_key });
                            }
                            return updatedProjectData;
                        });
                        setLoading(false);
                        resolve(result);
                    } else {
                        alert('Only "500 x 120" resolution allowed for branding image');
                        setLoading(false);
                        resolve({ status: 2000 });
                    }
                };
                img.onerror = () => {
                    alert('Error loading image');
                    setLoading(false);
                    reject(new Error('Error loading image'));
                };
                img.src = URL.createObjectURL(file);
            } else {
                alert('Only ".png" and ".jpeg" are allowed for branding image');
                setLoading(false);
                resolve({ status: 2000 });
            }
        });
    };

    const assest_upload1 = async (formData:any,linkLoc:any) => {
        try{
            setLoading(true)
            setLoadingMes('uploading')
            const result = await assestUpload(formData,linkLoc == 'android_app_url' ? 'Android': linkLoc == 'ios_app_url' ? 'ios' : linkLoc == 'webgl_app_url' ? 'webgl' : 'spatial',projectData[0]['project_name'])
            setProjectData((prevData) => {
                const updatedProjectData = [...prevData];
                if (updatedProjectData.length > 0) {
                    updatedProjectData[0][linkLoc] = result.data.s3_key;
                } else {
                    updatedProjectData.push({ [linkLoc]: result.data.s3_key });
                }
                return updatedProjectData;
            });
            setLoading(false);
            if(linkLoc == 'android_app_url'){
                setAndroidAssestUploaded(true)
            }
            else if(linkLoc == 'ios_app_url'){
                setIosAssestUploaded(true)
            }
            else if(linkLoc == 'webgl_app_url'){
                setWebglAssestUploaded(true)
            }
            else{
                setSpatialAssestUploaded(true)
            }
            return result
        }
        catch(err){
            setLoading(false);
            console.log(err)
        }
    }

    const handleProjectSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setLoadingMes('saving');
        
        try {
            console.log(projectData[0], sms , (code !== null ? true:false) , code)
            const result = await createProject(projectData[0], sms , (code !== null ? true:false) , code);
            if (result.status == 200) {
                if(result.data.message == 'story name already exist' || result.data.message == 'code already exist' || result.data.message == 'story name already exist in another project' || result.data.message == 'code already exist in another project'){
                    setIsError(true);
                    setError(result.data.message);
                }
                else{
                    navigate(`/configurator/form?page=stories&code=${result.data.code}`);
                }
            } else {
                console.log(result);
            }
           
        } catch (error) {
            setIsError(true);
            if (error instanceof AxiosError && error.response) {
                if(error.response.data.error?.includes('Name has already been taken')){
                    setError('Story name has already been taken');
                }
                if(error?.response?.data?.error?.includes("Account must exist, Account can't be blank, Code can't be blank, Name can't be blank")){
                    setError('code has already been taken');
                }
            } else {
                setError("An unexpected error occurred");
            }
        } finally {
            setLoading(false);
        }
    };
    

    const handleDeleteCustomField = (id: any, name: any) => {
        const index = projectFields[0]["project"].findIndex((item: { name: any }) => item.name === name);
        if (index !== -1 && index) {
            setProjectData((prev: any[]) => {
                const updatedProject = prev.map(project => {
                    const projectCustomFields = [...(project.project_custom_fields || [])];
                    const fieldIndex = projectCustomFields.findIndex((item: { attribute_value: any }) => item.attribute_value === name);
                    if (fieldIndex !== -1) {
                        projectCustomFields.splice(fieldIndex, 1);
                    }
                    return {
                        ...project,
                        project_custom_fields: projectCustomFields
                    };
                });
                return updatedProject;
            });
            setProjectFields((prev: any) => {
                const updatedProject = [...prev];
                const project = updatedProject[0].project;
                project.splice(index, 1)
                return updatedProject;
            });
        }
        if (id !== undefined) {
            deleteConfiguratorProjectCustomField(id);
        }
    }

    const handleFieldCreateSubmit = (input: any, index: any) => {
        setProjectData((prev: any[]) => {
            const updatedProject = [...prev];
            const project = updatedProject[index] || {};
            const projectCustomFields = project.project_custom_fields || {};
            projectCustomFields[Object.keys(projectCustomFields).length] = {
                id: projectCustomFields[Object.keys(projectCustomFields).length]?.id,
                attribute_value: input.name,
                field_type: input.type,
                value: '',
            };
            project.project_custom_fields = projectCustomFields;
            updatedProject[index] = project;
            return updatedProject;
        });
    }
    return (
        <div>
            <div style={{position:'relative'}}> 
                <form onSubmit={handleProjectSubmit}>
                    <div className="px-10 flex justify-between items-center">
                        <div className="px-10 py-5 ps-0 text-lg flex">
                                <p className='mr-4 text-xl' style={{fontSize:'30px',color:'#0050C8'}}>Story Setup </p>
                                        {/* {index !== 0 &&  */}
                                            {/* <button type="button" className="flex items-center"><FontAwesomeIcon icon={faTrash} className='text-sm' /></button>} */}
                        </div>
                        <button
                            type="submit"
                            style={{backgroundColor:'#058C7E'}}
                            className="text-white hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 rounded-lg text-sm px-5 py-2.5 text-center w-1/5 font-bold"
                        >
                            Save & Proceed
                        </button>
                    </div>
                    <div className='h-[73vh] w-full overflow-y-auto fixed top-100 mt-5'>
                        {Array.isArray(projectFields) && projectFields.map((fieldsObj, index) => {
                            const storyKey = Object.keys(fieldsObj)[0];
                            return (
                                <div key={index}>
                                    <div className='w-full h-auto flex'>
                                        <div className='w-full px-5 flex flex-wrap'>
                                            <Form fields={fieldsObj[storyKey]} handleChange={(e: (React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>), field) => handleProjectChange(e, index, field)} data={projectData[index] ?? []} handleDeleteCustomField={handleDeleteCustomField} audioConvert={(e:any) => audioConvert1(e)} selectedTextForConvertion={projectData[0]?.Text_for_audio} onboarding_audio_url={projectData[0]?.onboarding_audio_url} model_upload={(formData:any) => model_upload(formData)} model_url={projectData[0]?.uploaded_model} image_upload={(formData:any) => image_upload(formData)} image_url={projectData[0]?.branding_bg_url} tracking_mode_list={tracking_mode_list} template_type_list={template_type_list} uiPlacement_type_list={uiPlacement_type_list} audioUploaded={audioUploaded} imageUploaded={imageUploaded} modelUploaded={modelUploaded} alreadyAudioUploaded={alreadyAudioUploaded} alreadyImageUploaded={alreadyImageUploaded} alreadyModelUploaded={alreadyModelUploaded} alreadyAndroidAssestUploaded={alreadyAndroidAssestUploaded} alreadyIosAssestUploaded={alreadyIosAssestUploaded} alreadyWebglAssestUploaded={alreadyWebglAssestUploaded} alreadySpatialAssestUploaded={alreadySpatialAssestUploaded} audioUploadFailed={audioUploadFailed} audioPending={audioUrl.length == 0 ? false : true} androidAssestUploaded={androidAssestUploaded} iosAssestUploaded={iosAssestUploaded} webglAssestUploaded={webglAssestUploaded} spatialAssestUploaded={spatialAssestUploaded} assest_upload={(formData:any,linkLoc:string) => assest_upload1(formData,linkLoc)} customModel={customModel} threeDRepoOptions={threeDRepoOptions}/>
                                            <div style={{flexBasis: '100%',height:'0'}}></div>
                                            <div className="mx-5 w-1/5 items-end justify-end flex mb-10">
                                                <CustomFieldCreateBtn index={index} keyName={`project`} setFieldsCount={setProjectFields} handleFieldCreateSubmit={(inputValue: any) => handleFieldCreateSubmit(inputValue, index)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </form>
                {audioUrl && 
                    ( 
                        <div className="flex items-center justify-center mt-3" style={{position:'absolute',top:'0',left: '50%',transform: 'translateX(-50%)'}}> 
                            <audio controls className="me-2"> 
                                <source src={audioUrl} type="audio/wav" /> Your browser does not support the audio element. 
                            </audio> 
                            <button className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-sm text-center p-2.5 font-bold" onClick={(e)=>{audioUpload1(e)}} >upload</button>
                        </div> 
                    )
                } 

            </div>
            {loading &&
                <div style={{position:'fixed',top:'0',bottom:'0',width:'100%',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
                    <div style={{background:'white',border:' 2px solid #0050C8'}} className="flex items-center rounded px-7 py-3">
                        <div className="spinner me-2"></div>
                        {
                            loadingMes === 'converting' ?  (<p className="text-blue-700">Converting please wait...</p>)  : loadingMes === 'uploading' ? <p className="text-blue-700">Uploading please wait...</p> : loadingMes === 'saving' ? <p className="text-blue-700">Saving please wait...</p> : <p className="text-blue-700">Loading please wait...</p> 
                        }
                    </div>
                </div>
            }
            {popupForAudioComparision &&
                <div style={{position:'fixed',top:'0',bottom:'0',width:'100%',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
                    <div style={{background:'white',padding:'10px',width:'80%',height:'500px'}}>
                        <div className="w-full h-[20%] flex items-center justify-center">
                            <p className="text-center text-xl font-bold" style={{fontSize:'30px'}}>Please select one of the versions for audio conversion</p>
                        </div>
                        <div style={{background:'white',padding:'10px',width:'100%',display:'flex',alignItems:'start',justifyContent:'space-around',height:'80%'}}>
                            <div className="overflow-hidden w-[45%]  border h-full p-2">
                                <h4 className="text-center mb-4 underline">Pervious one</h4>
                                <div className="overflow-y-auto h-[70%]">
                                    <p>{projectData[0]['Text_for_audio']}</p>
                                </div>
                                <div className="flex justify-end mt-3">
                                    <button onClick={(e)=>(audioConvertionAfterComparision(e,'previousOne'))} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Your Text</button>
                                </div>
                            </div>
                            <div className="w-[1px] h-full bg-black"></div>
                            <div className="w-[45%] overflow-hidden overflow-y-auto border h-full p-2">
                                <h4 className="text-center mb-4 underline">Enhanced One</h4>
                                <div className="overflow-y-auto h-[70%]">
                                    <p>{enhancedTextVersion}</p>
                                </div>
                                <div className="flex justify-end mt-3">
                                    <button onClick={(e)=>(audioConvertionAfterComparision(e,'enhancedOne'))} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Enhanced Text</button>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            }
        </div>
        
    );
};

export default ProjectForm;